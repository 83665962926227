<div class="d-flex ml-3 mb-3 mt-2" *ngIf="orderHistoryList | async as orderList">
    <div>
        <button class="btn cstm-btn-primary" (click)="download('CSV')">
            <i class="fa fa-download mr-2"></i>{{"orderHistoryPage.csv" |cxTranslate}}
        </button>
        <button class="btn cstm-btn-light ml-3" (click)="download('PDF')">
            <i class="fa fa-download mr-2"></i>{{"orderHistoryPage.pdf" |cxTranslate}}
        </button>
    </div>
</div>
  